<template>
  <base-detail-view
    :loading="loading"
    :title="'Учебный материал, ' + typeMaterials[dataSource.Type] + ''"
    :isUnsaved="isUnsaved && valid"
    :showSave="!isEmployee"
    @onSave="save"
    @onRefresh="init"
  >
    <v-btn
      v-if="!simple"
      slot="action"
      @click="close"
      dark
      color="blue"
      class="ml-4"
      title="Закрыть"
    >
      <m-icon icon="mdi-close" left></m-icon>
      Закрыть
    </v-btn>
    <v-form class="block__content" :readonly="readOnly" v-model="valid">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model="dataSource.Name"
              label="Название"
              maxlength="200"
              placeholder="Введите название"
              autofocus
              :readonly="isEmployee"
              v-up-first-symbol
              required
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="dataSource.Description"
              label="Описание"
              maxlength="200"
              :readonly="isEmployee"
              v-up-first-symbol
            ></v-text-field>
          </v-col>
        </v-row>
        <input-load-file
          v-if="dataSource.Type === 1 && !isEmployee"
          :files="[dataSource.File]"
          @input="dataSource.File = $event[0]"
          @change="changeFile"
          accept=".doc, .docx"
          label="Выберите  текстовый документ для загрузки (.doc, .docx)"
          prepend-icon=""
          required
          :rules="[
            (value) =>
              !!value || 'Выберите текстовый документ формата .doc или .docx',
          ]"
          :hideClose="true"
        >
        </input-load-file>
        <document-select
          v-else-if="dataSource.Type === 2 && !isEmployee"
          :value="dataSource.Document"
          @input="changeDocument"
          :onlyStatus="5"
        />
        <v-text-field
          v-else-if="dataSource.Type === 3 && !isEmployee"
          v-model="dataSource.VideoUrl"
          label="Ссылка на видео (YouTube, VK)"
          maxlength="200"
          required
          :rules="[rules.required, rules.youtube]"
        ></v-text-field>
        <input-load-file
          v-else-if="dataSource.Type === 4 && !isEmployee"
          :files="[dataSource.File]"
          @input="dataSource.File = $event[0]"
          @change="changeFile"
          accept=".ppt, .pptx"
          label="Выберите файл презентации для загрузки (.ppt, .pptx)"
          prepend-icon=""
          required
          :rules="[
            (value) =>
              !!value || 'Выберите текстовый документ формата .ppt или .pptx',
          ]"
          :hideClose="true"
        >
        </input-load-file>
      </v-container>
    </v-form>
    <div class="mt-2">
      <v-container>
        <div v-if="isMaterialEmpty" class="matarial-edit__material-empty">
          <m-icon icon="mdi-alert-decagram" x-large></m-icon> <br />
          <b>Учебных материалов нет</b>
        </div>
        <material-pdf
          v-else-if="
            dataSource.File && (dataSource.Type === 1 || dataSource.Type === 4)
          "
          :externalId="dataSource.File.ExternalId"
        />
        <fileLibrary
          v-else-if="dataSource.Type === 2"
          :files.sync="dataSource.Document.Files"
          :isContentVisible.sync="dataSource.Document.IsContentVisible"
          :content.sync="dataSource.Document.Content"
          :isEnabledContent="false"
          :templateId="dataSource.Document.TemplateId"
          :id="dataSource.Document.Id"
          headerHidden
          :showAdd="false"
          :init="init"
          :save="save"
          ref="fileLibrary"
        >
        </fileLibrary>
        <material-youtube
          v-else-if="dataSource.Type === 3"
          :url="dataSource.VideoUrl"
        />
      </v-container>
    </div>

    <div
      class="mt-2 px-5 block__content"
      v-if="isEmployee && !this.dataSource.Date"
    >
      <v-row>
        <div>
          <v-col class="matarial-edit__confirm-inner-checkbox">
            <v-checkbox
              v-model="checkBox"
              class="matarial-edit__confirm-checkbox"
              label="Я подтверждаю, что настоящий  учебный материал мною полностью изучен"
              style="margin-left: 22px"
            ></v-checkbox>
          </v-col>
        </div>
        <v-col class="matarial-edit__confirm-inner-btn">
          <v-btn color="blue" dark :disabled="!checkBox" @click="execute"
            >Подтвердить</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </base-detail-view>
</template>
<script>
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import BaseDetailView from "@/layouts/BaseDetailView.vue";
import KnowledgeBaseService from "@/services/KnowledgeBaseService";
import MaterialYoutube from "./components/materials/youtube.vue";
import MaterialPdf from "./components/materials/pdf.vue";
import { KnowledgeBase, defaultFile } from "../../data/defaultObjects";
import documentSelect from "../../components/document/documentSelect.vue";
import InputLoadFile from "@/components/loadFiles/InputLoadFile.vue";
import fileLibrary from "@/components/FileLibrary.vue";

export default {
  name: "view-MaterialEdit",
  components: {
    BaseDetailView,
    MaterialYoutube,
    MaterialPdf,
    documentSelect,
    InputLoadFile,
    fileLibrary,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  props: {
    simple: { type: Boolean, default: false },
    isEmployee: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      apiService: !this.isEmployee
        ? KnowledgeBaseService.material
        : KnowledgeBaseService.materialEmployee,
      loading: true,
      dataSource: KnowledgeBase.material(),
      getDefaultObject: KnowledgeBase.material,
      getObjectText: () => this.dataSource.Name,
      valid: false,
      readOnly: false,
      rules: {
        required: (value) => !!value || "Укажите значение.",
        youtube: (url) => {
          return (
            !!this.youtube_parser(url) ||
            !!this.vk_parser(url) ||
            "Не верная ссылка"
          );
        },
      },
      typeMaterials: [
        "Тест",
        "Документ Word",
        "Документ",
        "Видео (YouTube, VK)",
        "Презентация",
      ],
      checkBox: false,
      funcUpdateItem: null,
    };
  },
  computed: {
    isMaterialEmpty() {
      return (
        !(this.dataSource.Type === 1 || this.dataSource.Type === 4) &&
        !this.dataSource.Document &&
        !this.dataSource.VideoUrl
      );
    },
    pageType() {
      const res =
        this.dataSource.Type === 0
          ? "test"
          : this.dataSource.Type === 1
          ? "word"
          : this.dataSource.Type === 2
          ? "document"
          : this.dataSource.Type === 3
          ? "video"
          : this.dataSource.Type === 4
          ? "presentation"
          : null;
      return res;
    },
  },
  watch: {
    "dataSource.Result": {
      deep: true,
      handler: function () {
        if (this.funcUpdateItem) {
          this.funcUpdateItem(this.dataSource);
        }
      },
    },
    "dataSource.File": function (newValue) {
      if (!this.dataSource.Name && newValue && newValue.Name) {
        const nameWithoutExtension = newValue.Name.replace(
          /\.(doc|docx|pdf|pptx|ppt)$/i,
          ""
        );
        this.$set(this.dataSource, "Name", nameWithoutExtension);
      }
    },
  },
  async created() {
    this.funcUpdateItem =
      this.$store.state.pageTabs.transaction.listViewDetail.listViewUpdateItems;
    this.$store.dispatch("pageTabs/TAKE_TRANSACTION_VIEW_DETAIL");
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async execute() {
      await KnowledgeBaseService.materialEmployee.execute(this.dataSource.Id);
      await this.init();
    },
    changeFile(files) {
      if (files.lastModified) {
        const file = defaultFile();
        const reader = new FileReader();
        reader.readAsDataURL(files);
        reader.onload = (e) => {
          const startIndex = e.target.result.indexOf("base64,");
          const strBase64 = e.target.result.substr(
            startIndex + 7,
            e.target.result.length
          );

          file.Base64Content = strBase64;
        };
        file.Name = files.name;
        this.$set(this.dataSource, "File", file);
      }
    },
    async save() {
      this.loading = true;
      this.baseSave();
    },
    close() {
      const activeTab = this.$store.state.pageTabs.tabs.find(
        (t) => t.route === this.$store.state.pageTabs.activeTab
      );

      if (!activeTab || Object.keys(activeTab).length === 0) return;

      this.$store.dispatch("pageTabs/CLOSE_TAB", {
        tab: activeTab,
        _router: this.$router,
      });
    },
    youtube_parser(url) {
      if (!url) return false;
      const regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      return match && match[7].length === 11 ? match[7] : false;
    },
    vk_parser(url) {
      if (!url || (!url.includes("vk.com") && !url.includes("vk.ru")))
        return false;
      let oid;
      let id;
      if (url.includes("video_ext")) {
        oid = url.match(/oid=(-?\d+)/)[1];
        id = url.match(/id=(\d+)/)[1];
      } else if (url.includes("video") || url.includes("clip")) {
        const pars = url.match(/[video|clip](-?[0-9]+)_([0 -9]+)/);
        if (pars.length >= 3) {
          oid = pars[1];
          id = pars[2];
        }
      }
      return oid && id ? `oid=${oid}&id=${id}` : false;
    },
    changeDocument(value) {
      if (value && Object.keys(value).length === 0) return;

      this.dataSource.Document = value;
      if (!this.dataSource.Name) this.dataSource.Name = value.Naimenovanie;
    },
  },
};
</script>
<style lang="scss">
.matarial-edit {
  &__confirm-inner-btn {
    align-self: center;
  }
  &__confirm-checkbox .v-label {
    font-weight: bold;
  }
  &__confirm-inner-checkbox {
    flex-grow: 0;
    white-space: nowrap;
  }
  &__material-empty {
    text-align: center;
    margin: 40px 0;
  }
}
</style>
